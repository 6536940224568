<template>
 <div>
<MyNewsNormal v-if="!$store.state.user.elderModel"/>
<MyNewsElder v-else/>
 </div>
</template>

<script>
import MyNewsNormal from './my-news-normal.vue'
import MyNewsElder from './my-news-elder.vue'
 export default {
   name: '',
   components: {
MyNewsNormal,
MyNewsElder
   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>
