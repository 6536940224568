<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnMe">
        <div></div>
        <span>系统消息</span>
      </div>
      <van-loading class="loading" v-if="refresh" size="24px"
        >加载中...</van-loading
      >
      <van-pull-refresh
        v-if="!refresh"
        v-model="isLoading"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            v-for="item in list"
            :key="item.id"
            @click="seeInformation(item.anntId, item)"
          >
            <div class="news-boder">
              <div class="news-boder-title">
                <img src="../../assets/img/me/info.png" alt="" />
                <div class="news-boder-title-left">
                  <div class="dot" v-if="item.readFlag == '0'"></div>
                  {{ item.titile }}
                </div>
                <div class="news-boder-title-time">{{ item.sendTime }}</div>
              </div>
              <div class="underline"></div>
              <div class="news-boder-content">{{ item.msgContent }}</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/layout.vue'
import { getMyAnnouncementSend, editByAnntIdAndUserId } from '@/api/me'
export default {
  components: {
    Layout
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      refresh: false
    }
  },
  mounted() {},
  methods: {
    returnMe() {
      this.$router.go(-1)
    },
    async onLoad() {
      try {
        // 1. 请求获取数据
        const result = await getMyAnnouncementSend({
          pageNo: this.pageNo,
          pageSize: this.pageSize
        })

        const list = result.result.records
        // 数组的展开操作符，它会把数组元素依次传入
        this.list.push(...list)

        // this.list = result.result.records
        // console.log(this.list)
        // 3. 本次数据加载结束之后要把加载状态设置为结束
        this.loading = false

        // 4. 判断数据是否全部加载完成
        if (result.result.records.length) {
          // 更新获取下一页数据的时间戳
          this.pageNo++
        } else {
          // 没有数据了，将 finished 设置为 true，不再 load 加载更多了
          this.finished = true
        }
      } catch (err) {
        // 展示错误提示状态
        this.error = true
        // 请求失败了，loading 也需要关闭
        this.loading = false
      }
    },
    seeInformation(id, item) {
      if (item.readFlag == 1) {
        return
      } else {
        editByAnntIdAndUserId({ anntId: id })
        this.list = []
        getMyAnnouncementSend({
          pageNo: 1,
          pageSize: 10
        }).then((res) => {
          this.list = res.result.records
        })
      }
    },
    async onRefresh() {
    this.list = []
    this.pageNo = 1
    this.onLoad()
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #fff;

  .layout /deep/ .main {
    background: #f2f3f5;
    padding-bottom: 0;
    padding-top: 56px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
      color: #ffffff;
    }
  }
  .news-boder {
    position: relative;
    padding: 15px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 4px;
    .underline {
      margin-top: 13px;
      width: 325px;
      height: 1px;
      background: #dee0e8;
      border-radius: 4px;
    }
    .news-boder-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #12151b;
      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      .news-boder-title-left {
        display: flex;
        align-items: flex-start;
        flex: 1;
        div {
          margin-right: 5px;
        }
      }
      .news-boder-title-time {
        font-size: 12px;
        color: #868a93;
      }
    }
    .news-boder-content {
      margin-top: 11px;
      font-size: 14px;
      color: #464a55;
      word-break: break-all;
    }
  }
  .dot {
    position: absolute;
    top: 30px;
    left: 5px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 10px;
  }
}
</style>